import { Fragment, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/bootstrap/Button";
import Card, { CardBody, CardTitle, } from "../../../components/bootstrap/Card";
import Page from "../../../layout/Page/Page";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator, } from "../../../layout/SubHeader/SubHeader";
import useFetch from "../../../hooks/useFetch";
import useFilters from "../../../hooks/useFilters";
import { handleConfirmationAlert } from "../../../components/ConfirmationAlert";
import ErrorMessage from "../../../components/ErrorMessage";
import { toast } from "react-toastify";
import AsyncImg from "../../../components/AsyncImg";
import { RecipeService } from "../../../services/recipes/recipeService";
import { RecipesApiResponse } from "../../../type/recipes-type";
import RecipesFilters from "./recipes-options/RecipesFilters";
import StatusDropdown from "../../../components/StatusDropdown";
import { CustomTable } from "../../../components/table/CustomTable";
import Allergens from "../../../components/Allergens";
import TechnicalSheetModal from "../components/TechnicalSheetModal";
import useHandleErrors from "../../../utils/hooks/useHandleErrors";
import Spinner from "../../../components/bootstrap/Spinner";

export interface IRecipeFilters {
    active?: number;
    group_food?: string[];
    ingredients?: string[];
    allergens?: string[];
}

const recipeFilters: IRecipeFilters = {
    active: 3,
    group_food: [],
    ingredients: [],
    allergens: [],
};

const RecipesList = () => {

    const { handleErrors } = useHandleErrors();
    const navigate = useNavigate();
    const recipeService = new RecipeService();

    const [changingStatus, setChangingStatus] = useState<string[]>([]);
    const [displayModal, setDisplayModal] = useState<boolean>(false);
    const [technicalSheetData, setTechnicalSheetData] = useState<{ id: string, name: string }>({ id: '', name: '' });

    const { filters, updateFilters, resetFilters, updateFilterOrder, updatePage, updatePageSize } = useFilters(recipeFilters);

    const [recipesData, fetchingRecipes, recipesError, refetchRecipes] = useFetch(useCallback(async () => {
        const recipeService = new RecipeService();
        const response = await recipeService.getRecipes(filters);
        return response.getResponseData() as RecipesApiResponse;
    }, [filters]));

    const getContent = () => {
        const toggleRecipeStatus = async (id: string, status: boolean, toggleStatus: Function) => {
            try {
                setChangingStatus([...changingStatus, id]);
                const response = await (await recipeService.toggleRecipeStatus(id)).getResponseData();
                if (response.success) {
                    setChangingStatus(changingStatus.filter((item) => item !== id));
                    toggleStatus(status);
                    refetchRecipes();
                    toast.success(status ? 'Se ha activado la receta' : 'Se ha desactivado la receta');
                } else {
                    setChangingStatus(changingStatus.filter((item) => item !== id));
                }
            } catch (error: any) {
                setChangingStatus(changingStatus.filter((item) => item !== id));
            }
        };

        const handleClone = async (id: string) => {
            try {
                const response = await (await (new RecipeService()).cloneRecipe(id)).getResponseData();
                if (response.success) {
                    refetchRecipes();
                    updateFilters({ ...filters });
                    toast.success('Receta clonada correctamente');
                } else {
                    handleErrors(response);
                }
            } catch (error: any) {
                toast.error(recipesError.message || 'Error al clonar la receta');
            }
        }

        const handleDelete = async (id: string) => {
            try {
                const response = await (await (new RecipeService()).deleteRecipe(id)).getResponseData();
                if (response.success) {
                    refetchRecipes();
                    updateFilters({ ...filters });
                    toast.success('Receta eliminada correctamente');
                } else {
                    setTimeout(() => {
                        toast.error(recipesError.message || 'Error al eliminar la receta');
                    }, 100);
                }
            } catch (error: any) {
                toast.error(recipesError.message || 'Error al eliminar la receta');
            }
        };

        let apiData = recipesData as RecipesApiResponse;

        return (
            <CustomTable
                title="Recetas"
                data={apiData.recipes ? apiData.recipes : null}
                pagination={true}
                paginationData={{
                    pageSize: filters.limit,
                    currentPage: filters.page,
                    pageCount: apiData as RecipesApiResponse ? apiData.lastPage : 1,
                    handlePagination: (page: any) => {
                        updatePage({ selected: page.selected + 1 });
                    },
                    handlePerPage: updatePageSize,
                }}
                className={"table-striped table-hover"}
                columns={[
                    {
                        name: "",
                        keyValue: "RecipeImage",
                        render: (element: any) => {
                            return (
                                <div className="d-flex justify-content-center">
                                    <div className='user-avatar'>
                                        <AsyncImg id={element.RecipeImage ? element.RecipeImage.id : null} food />
                                    </div>
                                </div>
                            );
                        },
                    },
                    {
                        name: "Nombre",
                        keyValue: "name",
                        sortable: true,
                        sortColumn: updateFilterOrder,
                        render: (element: any) => {
                            return (
                                <div className="cursor-pointer text-secondary" onClick={() => { navigate(`${element.id}/edit`) }}>
                                    {element.name}
                                </div>
                            )
                        },
                    },
                    {
                        name: "Grupo de alimentos",
                        keyValue: "foodGroup",
                        render: (element: any) => {
                            return (
                                <>
                                    {element.foodGroup ? element.foodGroup.name : "-"}
                                </>
                            );
                        },
                    },
                    {
                        name: "Ingredientes",
                        keyValue: "ingredients",
                        render: (element: any) => {
                            return (
                                <>
                                    {
                                        element.ingredients.map((ingredient: any, index: number) => {
                                            return (
                                                <div key={index}>
                                                    {ingredient.food ? ingredient.food.name : "-"}
                                                </div>
                                            )
                                        })
                                    }
                                </>
                            )
                        }
                    },
                    {
                        name: "Alérgenos",
                        keyValue: "allergens",
                        render: (element: any) => {
                            return (
                                <>
                                    {
                                        <Allergens recipe={element} /> || "-"
                                    }
                                </>
                            )
                        }
                    },
                    {
                        name: "Calorías (kcal)",
                        keyValue: "energy",
                        sortable: true,
                        sortColumn: updateFilterOrder,
                        render: (element: any) => {
                            return (
                                <>
                                    {Math.round(element.energy) || "0"}
                                </>
                            );
                        },
                    },
                    {
                        name: "Proteínas (g)",
                        keyValue: "proteins",
                        sortable: true,
                        sortColumn: updateFilterOrder,
                        render: (element: any) => {
                            return (
                                <>
                                    {Math.round(element.proteins) || "0"}
                                </>
                            );
                        },
                    },
                    {
                        name: "H.C (g)",
                        keyValue: "carbohydrates",
                        sortable: true,
                        sortColumn: updateFilterOrder,
                        render: (element: any) => {
                            return (
                                <>
                                    {Math.round(element.carbohydrates) || "0"}
                                </>
                            );
                        },
                    },
                    {
                        name: "Grasas (g)",
                        keyValue: "fat",
                        sortable: true,
                        sortColumn: updateFilterOrder,
                        render: (element: any) => {
                            return (
                                <>
                                    {Math.round(element.fat) || "0"}
                                </>
                            );
                        },
                    },
                    {
                        name: "Estado",
                        keyValue: "active",
                        className: "text-center",
                        render: (element: any) => {
                            return (
                                <div className={'text-center'}>
                                    <StatusDropdown
                                        disabled={changingStatus.includes(element.id)}
                                        itemId={element.id}
                                        status={element.active}
                                        change={toggleRecipeStatus}
                                    />
                                </div>
                            );
                        },
                    },
                    { name: "Acciones", className: "min-w-100px text-end", isActionCell: true }
                ]}
                actions={[
                    {
                        title: "Ficha técnica",
                        icon: "Description",
                        buttonType: 'icon',
                        additionalClasses: 'text-primary',
                        description: "Ficha técnica",
                        click: (item: any) => {
                            setTechnicalSheetData({ id: item.id, name: item.name });
                            setDisplayModal(true)
                        },
                    },
                    {
                        title: 'Clonar',
                        icon: 'FileCopy',
                        buttonType: 'icon',
                        additionalClasses: 'text-primary',
                        description: 'Clonar receta',
                        click: (item: any) => { handleClone(item.id) }
                    },
                    {
                        title: "Editar",
                        icon: "Edit",
                        buttonType: 'icon',
                        additionalClasses: 'text-primary',
                        description: "Editar receta",
                        click: (item: any) => {
                            navigate(`${item.id}/edit`);
                        },
                    },
                    {
                        title: "Eliminar",
                        icon: "Delete",
                        buttonType: 'icon',
                        additionalClasses: 'text-danger',
                        description: "Eliminar receta",
                        click: (item: any) => {
                            handleConfirmationAlert({
                                title: "Eliminar receta",
                                text: "¿Está seguro que desea eliminar la receta?",
                                icon: "warning",
                                onConfirm: () => {
                                    handleDelete(item.id);
                                }
                            })
                        },
                    },
                ]}
            />
        );
    };

    return (
        <Fragment>
            <SubHeader>
                <SubHeaderLeft>
                    <CardTitle>Listado de Recetas</CardTitle>
                    <SubheaderSeparator />
                    <Button
                        title="Añadir Receta"
                        color="storybook"
                        icon="Add"
                        isLight
                        onClick={() => {
                            navigate("create");
                        }}
                    >
                    </Button>
                </SubHeaderLeft>
                <SubHeaderRight>
                    <RecipesFilters updateFilters={updateFilters} filters={filters} resetFilters={resetFilters} updateFilterOrder={updateFilterOrder} />
                </SubHeaderRight>
            </SubHeader>
            <Page container="fluid">
                {displayModal && <TechnicalSheetModal isOpen={displayModal} setIsOpen={setDisplayModal} data={technicalSheetData} />}

                <Card stretch={true}>
                    <CardBody className="table-responsive" isScrollable={true}>
                        <Fragment>
                            {recipesError && <ErrorMessage error={recipesError} />}

                            {recipesData && recipesData?.recipes
                                ? getContent()
                                : !recipesError && <div className="text-center"><Spinner /></div>
                            }
                        </Fragment>
                    </CardBody>
                </Card>
            </Page>
        </Fragment>
    );
};

export default RecipesList;