import { FC, useCallback } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import Modal, { ModalBody, ModalHeader, ModalTitle } from "../../../components/bootstrap/Modal";
import { CardBody, CardFooter } from "../../../components/bootstrap/Card";
import Checks from "../../../components/bootstrap/forms/Checks";
import { RecipeService } from "../../../services/recipes/recipeService";
import FormLabel from "../../../components/FormLabel";
import { Ration } from "../../../redux/authSlice";
import * as Yup from 'yup';
import { CompanyService } from "../../../services/companies/organizationService";
import useFetch from "../../../hooks/useFetch";
import '../../../styles/styles.scss';
import Textarea from "../../../components/bootstrap/forms/Textarea";

interface CreateTemplateModalProps {
    isOpen: boolean;
    setIsOpen(...args: unknown[]): unknown;
    data: { id: string, name: string };
}

interface TechnicalSheetForm {
    foodRationConfiguration: Ration[];
    name: boolean;
    foodGroup: boolean;
    ingredients_rations: boolean;
    allergens: boolean;
    techniques: boolean;
    elaboration: boolean;
    presentation: boolean;
    storageConditions: boolean;
    processingTime: boolean;
    nutritionalInformation: boolean;
    observations: string;
}

const initialValues: TechnicalSheetForm = {
    foodRationConfiguration: [],
    name: true,
    foodGroup: true,
    ingredients_rations: true,
    allergens: true,
    techniques: true,
    elaboration: true,
    presentation: true,
    storageConditions: true,
    processingTime: true,
    nutritionalInformation: true,
    observations: '',
};

const validationSchema = Yup.object({
    foodRationConfiguration: Yup.array(),
    name: Yup.boolean(),
    foodGroup: Yup.boolean(),
    ingredients_rations: Yup.boolean(),
    allergens: Yup.boolean(),
    techniques: Yup.boolean(),
    elaboration: Yup.boolean(),
    presentation: Yup.boolean(),
    storageConditions: Yup.boolean(),
    processingTime: Yup.boolean(),
    nutritionalInformation: Yup.boolean(),
    observations: Yup.string().nullable()
});

const TechnicalSheetModal: FC<CreateTemplateModalProps> = ({ isOpen, setIsOpen, data }) => {

    const recipeService = new RecipeService();
    const companyService = new CompanyService();

    const [foodRations] = useFetch(useCallback(async () => {
        const response = (await companyService.getFoodRationConfigurations());
        return response.getResponseData();
    }, []));

    const downloadTechnicalSheet = async (values: any) => {
        values.recipe = data.id;

        try {
            const response = (await recipeService.downloadDocument(values));
            if (response) {
                const fileData = response.getResponseData();
                const blob = new Blob([fileData]);
                const url = window.URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', data.name + '.pdf');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            } else {
                toast.error('Error al descargar la ficha técnica');
            }
        } catch (error: any) {
            toast.error(error.message);
        }
    };

    const foodRationsConfig = () => {
        return foodRations?.map((r: any) => {
            return {
                id: r.id,
                name: r.name
            }
        });
    }

    const verifyClass = (inputFieldID: keyof TechnicalSheetForm) => { return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? 'is-invalid' : '' }

    const showErrors = (inputFieldID: keyof TechnicalSheetForm) => {
        // @ts-ignore
        return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? <div className="invalid-feedback">{formik.errors[inputFieldID]} </div> : <></>;
    }

    const formik = useFormik<TechnicalSheetForm>({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values) => { downloadTechnicalSheet(values) }
    });

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} size='md' titleId='Ficha técnica de la receta'>
            <ModalHeader setIsOpen={setIsOpen} className='p-4'>
                <ModalTitle id='technical_sheet-data'>Generar ficha técnica</ModalTitle>
            </ModalHeader>

            <ModalBody className='px-4'>
                <form onSubmit={formik.handleSubmit} autoComplete="off">
                    <CardBody className="row">
                        <div className="col-md-12 mb-3">
                            <h5>Raciones que se mostrarán:</h5>
                        </div>

                        {foodRationsConfig()?.map((r: any, index: number) => {
                            return (
                                <div className={`col-md-6 d-flex flex-row align-items-center ${ index !== 0 && index !== 1 ? 'mt-2' : '' }`} key={index}>
                                    <Checks
                                        id={r.id}
                                        checked={formik.values?.foodRationConfiguration?.includes(r.id)}
                                        onChange={() => {
                                            if (formik.values?.foodRationConfiguration?.includes(r.id)) {
                                                formik.setFieldValue('foodRationConfiguration', formik.values?.foodRationConfiguration?.filter((item: any) => item !== r.id));
                                            } else {
                                                formik.setFieldValue('foodRationConfiguration', [...formik.values?.foodRationConfiguration, r.id]);
                                            }
                                        }}
                                        onBlur={formik.handleBlur}
                                    />
                                    <FormLabel label={r.name} className="w-auto me-2 my-auto" htmlFor={r.id} />
                                    {formik.values?.foodRationConfiguration?.length > 0 && formik.values?.foodRationConfiguration?.includes(r.id) ?
                                        formik.values?.foodRationConfiguration?.map((item: any , indexRation: number) => {
                                            return item === r.id ? <div key={indexRation} className="border border-secondary px-2 bg-secondary fw-bold order-rations">{indexRation + 1}</div> : <></>
                                        }) :
                                     <></>}
                                </div>
                            )
                        })}

                        <div className="col-md-12 mt-4 mb-3">
                            <h5>Selecciona los campos que quieres exportar:</h5>
                        </div>

                        <div className="col-md-6 d-flex flex-row">
                            <Checks
                                id='name'
                                checked={formik.values.name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className={verifyClass('name')}
                            />
                            <FormLabel label="Nombre" className="w-auto me-2" htmlFor='name' />
                            {showErrors('name')}
                        </div>

                        <div className="col-md-6 d-flex flex-row">
                            <Checks
                                id='foodGroup'
                                checked={formik.values.foodGroup}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className={verifyClass('foodGroup')}
                            />
                            <FormLabel label="Grupo de alimentos" className="w-auto me-2" htmlFor='foodGroup' />
                            {showErrors('foodGroup')}
                        </div>

                        <div className="col-md-6 d-flex flex-row">
                            <Checks
                                id='ingredients_rations'
                                checked={formik.values.ingredients_rations}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className={verifyClass('ingredients_rations')}
                            />
                            <FormLabel label="Ingredientes y raciones" className="w-auto me-2" htmlFor='ingredients_rations' />
                            {showErrors('ingredients_rations')}
                        </div>

                        <div className="col-md-6 d-flex flex-row">
                            <Checks
                                id='allergens'
                                checked={formik.values.allergens}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className={verifyClass('allergens')}
                            />
                            <FormLabel label="Alérgenos" className="w-auto me-2" htmlFor='allergens' />
                            {showErrors('allergens')}
                        </div>

                        <div className="col-md-6 d-flex flex-row">
                            <Checks
                                id='techniques'
                                checked={formik.values.techniques}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className={verifyClass('techniques')}
                            />
                            <FormLabel label="Técnicas culinarias" className="w-auto me-2" htmlFor='techniques' />
                            {showErrors('techniques')}
                        </div>

                        <div className="col-md-6 d-flex flex-row">
                            <Checks
                                id='elaboration'
                                checked={formik.values.elaboration}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className={verifyClass('elaboration')}
                            />
                            <FormLabel label="Elaboración" className="w-auto me-2" htmlFor='elaboration' />
                            {showErrors('elaboration')}
                        </div>

                        <div className="col-md-6 d-flex flex-row">
                            <Checks
                                id='presentation'
                                checked={formik.values.presentation}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className={verifyClass('presentation')}
                            />
                            <FormLabel label="Presentación de la ración" className="w-auto me-2" htmlFor='presentation' />
                            {showErrors('presentation')}
                        </div>

                        <div className="col-md-6 d-flex flex-row">
                            <Checks
                                id='storageConditions'
                                checked={formik.values.storageConditions}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className={verifyClass('storageConditions')}
                            />
                            <FormLabel label="Cond. de almacenamiento" className="w-auto me-2" htmlFor='storageConditions' />
                            {showErrors('storageConditions')}
                        </div>

                        <div className="col-md-6 d-flex flex-row">
                            <Checks
                                id='processingTime'
                                checked={formik.values.processingTime}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className={verifyClass('processingTime')}
                            />
                            <FormLabel label="Tiempo de elaboración" className="w-auto me-2" htmlFor='processingTime' />
                            {showErrors('processingTime')}
                        </div>

                        <div className="col-md-6 d-flex flex-row">
                            <Checks
                                id='nutritionalInformation'
                                checked={formik.values.nutritionalInformation}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className={verifyClass('nutritionalInformation')}
                            />
                            <FormLabel label="Información nutricional" className="w-auto me-2" htmlFor='nutritionalInformation' />
                            {showErrors('nutritionalInformation')}
                        </div>

                        <div className="col-md-12 mt-3">
                            <h5>Observaciones:</h5>
                            <Textarea 
                                id="observations"
                                value={formik.values.observations || ""}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className={verifyClass('observations')}
                            />
                        </div>
                    </CardBody>

                    <CardFooter className="d-flex justify-content-end">
                        <button type="submit" className="btn btn-primary">Generar</button>
                    </CardFooter>
                </form>
            </ModalBody>
        </Modal>
    )
}

export default TechnicalSheetModal;